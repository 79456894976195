<template>
  <div class="registered">
    <TopBar :type="2" :pageTitle="'选择注册类型'"/>
    <ul class="type-list">
      <li class="type type_1">
        <p class="title">个人用户</p>
        <p class="introduce">注册普通个人用户则可以通过平台了解并查看部分直播、录播、面授、模拟考试等相关课程信息</p>
        <router-link class="btn blue" to='/registered/1'>注册个人用户</router-link>
      </li>
      <li class="type type_2">
        <p class="title">企业用户</p>
        <p class="introduce">注册普通个人用户则可以通过平台了解并查看部分直播、录播、面授、模拟考试等相关课程信息</p>
        <p class="btn grey">注册企业用户</p>
      </li>
      <li class="type type_3">
        <p class="title">职业讲师</p>
        <p class="introduce">注册普通个人用户则可以通过平台了解并查看部分直播、录播、面授、模拟考试等相关课程信息</p>
        <p class="btn grey">暂时无法注册职业教师</p>
      </li>
    </ul>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'WelcomeRegistered',
  components: {
    TopBar
  }
}
</script>
<style scoped lang="scss">
.type-list{
  width: 480px;
  padding: 45px 0 110px;
  margin: 0 auto;
  .type{
    box-sizing: border-box;
    width: 480px;
    box-shadow: 0 0 5px #000C28;
    padding: 240px 44px 66px;
    margin-bottom: 28px;
    &.type_1{
      background: url(~@/assets/head-default-personal.png) no-repeat center 58px;
      background-size: auto 156px;
    }
    &.type_2{
      background: url(~@/assets/head-default-lecturer.png) no-repeat center 58px;
      background-size: auto 156px;
    }
    &.type_3{
      background: url(~@/assets/head-default-enterprise.png) no-repeat center 58px;
      background-size: auto 156px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .title{
    padding-top: 25px;
    font-size: 32px;
    line-height: 32px;
    color: #333;
    font-weight: 800;
    text-shadow: 0px 0px 10px rgba(0, 12, 40, 0.14);
    text-align: center;
  }
  .introduce{
    font-size: 24px;
    color: #999;
    line-height: 31px;
    text-shadow: 0px 0px 10px rgba(0, 12, 40, 0.14);
    margin: 24px auto 0;
  }
  .btn{
    display: block;
    width: 360px;
    height: 70px;
    line-height: 70px;
    box-shadow: 0px 0px 10px 0px rgba(0, 12, 40, 0.14);
    border-radius: 6px;
    color: #fff;
    text-align: center;
    margin: 48px auto 0;
    &.blue{
      background: #254ED4;
    }
    &.grey{
      background-color: #999;
    }
  }
}
</style>