<template>
  <div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      siteInfo: '',
      ssologinid: '',
      ticket: '',
    }
  },
  mounted() {
    this.siteInfo = localStorage.getItem('siteInfo')? JSON.parse(localStorage.getItem('siteInfo')):''
    this.ssologinid = ''
    this.ticket = ''
    // 贵人家园
    if (this.siteInfo.id === 106) {
      if (this.$route.query.ticket) {
        this.ssologinid = this.$route.query.ssologinid
        this.ticket = this.$route.query.ticket
        localStorage.setItem('ssologinid', this.$route.query.ssologinid)
        localStorage.setItem('ticket', this.$route.query.ticket)
        this.grnLoin()
      }
    }
  },
  methods: {
    // 贵人家园自动登录验证
    grnLoin() {
      this.$axios.get(`/v1/gzLoginValidate?ssologinid=${this.ssologinid}&ticket=${this.ticket}`).then((res) => {
        if (res.code == 0) {
          this.$axios.post(`/v1/gzLoginAuto`,{
            ssologinid: this.ssologinid,
          }).then((res) => {
            if (res.code == 0) {
              //this.logModal = false;
            this.userInfo = res.data.user;
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }).then(() => {
                this.$router.replace('/')
                // setTimeout(() => {
                //   window.location.reload()
                // }, 1000);
            });
            } else {
                this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        } else if (res.code == 1){
          this.$message({
          message: res.msg,
          type: 'error'
        });
        }
      });
    },
  }
}
</script>