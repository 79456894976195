<template>
  <div class="live">
    <iframe :src="link" frameborder="0"></iframe>
  </div>
</template>
<script>
export default {
  name: 'Live',
  data(){
    return {
     link: ''
    }
  },
  mounted(){
    this.link = 'https://live.polyv.cn/watch/'+this.$route.params.no;
    if( window.location.host=='culrcx.kskstudy.com'){
      this.getInfo()
    }
  },
  methods: {
    getInfo(){
      this.$axios.post('/v1/live/record',{live_id:this.$route.params.live_id},{useLog:true}).then(res=>{
        console.log(res)
      })
    }
  }
}
</script>
<style scoped lang="scss">
iframe{
  width: 100%;
  height: 100vh;
}
</style>