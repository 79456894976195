<template>
  <div class="registered">
    <div class="reg-content enterprise">
      <h1 class="reg-content-title" v-if="siteCont.id==106">欢迎加入黔南州网上工匠学院</h1>
      <h1 class="reg-content-title" v-if="siteCont.id !=106">欢迎加入KSK</h1>
      <div class="reg-steps">
        <span class="reg-step-text now">请选择加入身份</span>
        <span class="reg-step-arrows"></span>
        <span class="reg-step-text">请填写注册信息</span>
        <span class="reg-step-arrows"></span>
        <span class="reg-step-text">注册审核</span>
      </div>
      <ul class="reg-type-list">
        <li class="reg-type">
          <div class="head-portrait personal"></div>
          <div class="reg-title">个人用户</div>
          <div class="reg-introduce">注册普通个人用户则可以通过平台了解并查看部分直播、录播、面授、模拟考试等相关课程信息</div>
          <router-link class="reg-btn blue" to='/registered/1'>注册个人用户</router-link>
        </li>
        <li class="reg-type">
          <div  class="head-portrait enterprise"></div>
          <div class="reg-title">企业用户</div>
          <div class="reg-introduce">注册企业用户，可以成为我们的渠道代理关系，开通并管理下属平台运营情况</div>
          <button class="reg-btn greey" v-if="siteCont.id==99 || siteCont.id==106">注册企业用户</button>
          <router-link v-else class="reg-btn blue" to='/registered/2'>注册企业用户</router-link>
        </li>
        <li class="reg-type">
          <div  class="head-portrait lecturer"></div>
          <div class="reg-title">职业讲师</div>
          <div class="reg-introduce">注册职业讲师，将于平台达成讲师签约、讲课授权、课程研发，并享受课程收益等权益</div>
          <button class="reg-btn greey">暂时无法注册职业教师</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      siteCont:{}
    }
  },
  mounted(){
    if(localStorage.getItem('siteInfo')){
      this.siteCont = JSON.parse(localStorage.getItem('siteInfo'))
    }
  }
}
</script>

<style scoped lang="scss">
.registered{
  padding: 64px 0 88px;
  background-color: #F7F7F7;
}
.reg-content{
  box-sizing: border-box;
  width: 1200px;
  padding: 74px 116px 89px;
  background-color: #fff;
  margin: 0 auto;
}
.reg-content-title{
  font-size: 24px;
  font-weight: 900;
  color: #333;
  text-align: center;
  margin-bottom: 54px;
}
.reg-steps{
  display: flex;
  justify-content: center;
  margin-bottom: 90px;
}
.reg-step-text{
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  &::before{
    content: '';
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 2px solid #D8D8D8;
    border-radius: 50%;
    //background: url(~@/assets/selected-ico.png) no-repeat;
    margin-right: 10px;
  }
  &.now{
    color: #254ED4;
    &::before{
      border:none;
      background: url(~@/assets/selected-ico.png) no-repeat;
    }
  }
}
.reg-step-arrows{
  width: 46px;
  height: 16px;
  background: url(~@/assets/arrows-steps.png) no-repeat;
  background-size: 100%;
  margin: 0 21px;
}
.reg-type-list{
  display: flex;
  justify-content: space-between;
}
.reg-type{
  box-sizing: border-box;
  width: 270px;
  padding: 33px 23px 35px 25px;
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #eee;
  &:hover{
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  }
}
.head-portrait{
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 0 auto 14px;
  &.personal{
    background: url(~@/assets/head-default-personal.png) no-repeat;
    background-size: 100% 100%;
  }
  &.enterprise{
    background: url(~@/assets/head-default-enterprise.png) no-repeat;
    background-size: 100% 100%;
  }
  &.lecturer{
    background: url(~@/assets/head-default-lecturer.png) no-repeat;
    background-size: 100% 100%;
  }
}
.reg-title{
  font-size: 18px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  margin-bottom: 14px;
}
.reg-introduce{
  font-size: 14px;
  color: #999;
  line-height: 19px;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
}
.reg-btn{
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  font-size: 16px;
  color: #FFF;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
  &.blue{
    background-color: #254ED4;
    cursor: pointer;
  }
  &.greey{
    background-color: #999;
  }
}
</style>