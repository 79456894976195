<template>
  <div class="live">
    <iframe class="iframe"  :src="link" frameborder="0" scrolling="no" allowfullscreen="true" style="border:0"></iframe>
  </div>
</template>
<script>
import share from "@/utils/share.js";
export default {
  name: 'Live',
  data(){
    return {
     link: ''
    }
  },
  mounted(){
    this.link = 'https://live.polyv.cn/watch/'+this.$route.params.no;
    if( window.location.host=='culrcx.kskstudy.com'){
      this.getInfo()
    }
    this.getShare();
  },
  methods: {
    getInfo(){
      this.$axios.post('/v1/live/record',{live_id:this.$route.params.live_id},{useLog:true}).then(res=>{
        console.log(res)
      })
    },
    getShare() {
      let nowData = new Date()
      let text=''
      this.$axios.post(`/v1/live/detail`,{id:this.$route.params.live_id}).then(res=>{
        if(res.code==1){
          if(nowData>new Date(res.data.created_at*1000)){
            text='正在直播'
          }else{
            text='直播预告'
          }
          const shareObj = {
            title: res.data.name+'-'+text, // 分享标题
            // link: pageUrl, // 分享链接
            desc: res.data.intro, // 分享描述
            img: res.data.mobile_image, // 分享图标
            // link2:location.href
          };
          share(shareObj);
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
</style>